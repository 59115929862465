import React, { useState, useEffect } from 'react';
import { BarChart, Bar, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';

const DomainModal = ({ domain, onClose }) => {
  const [details, setDetails] = useState({ packets: [], flows: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await fetch(`/api/domain_details/${domain}`);
        const data = await response.json();
        setDetails(data);
      } catch (error) {
        console.error('Error fetching domain details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [domain]);

  const renderTable = (data, type) => (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Timestamp</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Source IP</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Destination IP</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Protocol</th>
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Device</th>
          {type === 'flows' && (
            <>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Application</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
            </>
          )}
          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {data.map((item) => (
          <tr key={item.id}>
            <td className="px-6 py-4 whitespace-nowrap">{new Date(item.timestamp).toLocaleString()}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.src_ip}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.dst_ip}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.protocol}</td>
            <td className="px-6 py-4 whitespace-nowrap">{item.device_name}</td>
            {type === 'flows' && (
              <>
              <td className="px-6 py-4 whitespace-nowrap">{item.application}</td>
              <td className="px-6 py-4 whitespace-nowrap">{item.nfstream_category}</td>
            </>
            )}
            <td className="px-6 py-4 whitespace-nowrap">
              <button 
                onClick={() => alert(JSON.stringify(item.data, null, 2))} 
                className="text-blue-600 hover:text-blue-900"
              >
                View Full Data
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={onClose}>
      <div className="relative top-20 mx-auto p-5 border w-11/12 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
        <div className="mt-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{domain} Details</h3>
          <div className="mt-2">
            <h4 className="text-md font-medium mb-2">Packets</h4>
            <div className="overflow-x-auto">
              {renderTable(details.packets, 'packets')}
            </div>
            <h4 className="text-md font-medium mt-6 mb-2">Flows</h4>
            <div className="overflow-x-auto">
              {renderTable(details.flows, 'flows')}
            </div>
          </div>
        </div>
        <div className="items-center px-4 py-3 mt-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

const Dashboard = () => {
  const [protocolDistribution, setProtocolDistribution] = useState([]);
  const [applicationDistribution, setApplicationDistribution] = useState([]);
  const [classificationDistribution, setClassificationDistribution] = useState([]);
  const [nfstreamCategoryDistribution, setNfstreamCategoryDistribution] = useState([]);
  const [domainSummaries, setDomainSummaries] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterDevice, setFilterDevice] = useState('');
  const [filterDomain, setFilterDomain] = useState('');
  const [sortBy, setSortBy] = useState('domain');
  const [sortOrder, setSortOrder] = useState('asc');
  const [allDevices, setAllDevices] = useState([]);

  useEffect(() => {
    fetchDistributions();
    fetchDomainSummaries();
  }, [currentPage, filterDevice, filterDomain, sortBy, sortOrder]);

  const fetchDistributions = async () => {
    const response = await fetch('/api/distributions');
    const data = await response.json();
    setProtocolDistribution(data.protocol);
    setApplicationDistribution(data.application);
    setClassificationDistribution(data.classification);
    setNfstreamCategoryDistribution(data.nfstream_category);
  };

  const fetchDomainSummaries = async () => {
    const response = await fetch(`/api/domain_summary?page=${currentPage}&device=${filterDevice}&domain=${filterDomain}&sort_by=${sortBy}&sort_order=${sortOrder}`);
    const data = await response.json();
    setDomainSummaries(data.domains);
    setTotalPages(data.pages);
    setAllDevices(data.all_devices);
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * Math.PI / 180);
    const y = cy + radius * Math.sin(-midAngle * Math.PI / 180);
  
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const renderDeviceNames = (deviceNames) => {
    const devices = deviceNames.split(', ');
    return devices.map((device, index) => (
      <span key={index} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
        {device}
      </span>
    ));
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">TrafficLens Dashboard</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Protocol Distribution</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={protocolDistribution}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">Traffic Classification</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={classificationDistribution}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {classificationDistribution.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4">NFStream Category Distribution</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={nfstreamCategoryDistribution}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {nfstreamCategoryDistribution.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Application Distribution</h2>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={applicationDistribution} layout="vertical">
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis type="number" />
            <YAxis dataKey="name" type="category" width={150} />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      

      <div className="bg-white p-6 rounded-lg shadow-md mb-8">
        <h2 className="text-xl font-semibold mb-4">Domain Summary</h2>
        <div className="flex mb-4">
          <input
            type="text"
            placeholder="Filter by domain..."
            value={filterDomain}
            onChange={(e) => setFilterDomain(e.target.value)}
            className="p-2 border rounded mr-2"
          />
          <select
            value={filterDevice}
            onChange={(e) => setFilterDevice(e.target.value)}
            className="p-2 border rounded"
          >
            <option value="">All Devices</option>
            {allDevices.map((device) => (
              <option key={device} value={device}>{device}</option>
            ))}
          </select>
        </div>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {['Domain', 'Packets', 'Flows', 'Device', 'Ads', 'Trackers', 'Annoyances', 'Cookies', 'Social'].map((header) => (
                <th
                  key={header}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort(header.toLowerCase())}
                >
                  {header}
                  {sortBy === header.toLowerCase() && (
                    <span className="ml-1">{sortOrder === 'asc' ? '▲' : '▼'}</span>
                  )}
                </th>
              ))}
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
              {domainSummaries.filter(domain => domain.domain).map((domain) => (
              <tr key={domain.domain}>
                <td className="px-6 py-4 whitespace-nowrap">{domain.domain}</td>
                <td className="px-6 py-4 whitespace-nowrap">{domain.packet_count}</td>
                <td className="px-6 py-4 whitespace-nowrap">{domain.flow_count}</td>
                <td className="px-6 py-4">{renderDeviceNames(domain.device_names)}</td>
                <td className="px-6 py-4 whitespace-nowrap">{domain.advertisements}</td>
                <td className="px-6 py-4 whitespace-nowrap">{domain.trackers}</td>
                <td className="px-6 py-4 whitespace-nowrap">{domain.annoyances}</td>
                <td className="px-6 py-4 whitespace-nowrap">{domain.cookies}</td>
                <td className="px-6 py-4 whitespace-nowrap">{domain.social}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => setSelectedDomain(domain.domain)}
                    className="text-blue-600 hover:text-blue-900"
                  >
                    View Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4 flex justify-between">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>

      {selectedDomain && (
        <DomainModal
          domain={selectedDomain}
          onClose={() => setSelectedDomain(null)}
        />
      )}
    </div>
  );
};

export default Dashboard;